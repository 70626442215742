import React from 'react';
import Clock from './components/Clock'
import './App.css';

function App() {
  const endTimestamp = new Date("Dec 14, 2021 GMT-05:00").getTime();

  return (
    <div className="App">
      <Clock endTime={endTimestamp} afterMessage="Happy Birthday, Kendal!" />
    </div>
  );
}

export default App;
