import React, { useEffect, useState } from 'react';

interface Props {
    endTime: number;
    afterMessage: string;
}

interface TimeLeft {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
    diff: number;
}

const Clock = (props: Props) => {
    const calculateTimeLeft = (): TimeLeft | undefined => {
        const currentDate = new Date();
        let difference = +props.endTime - +currentDate;

        if (difference < -(60 * 60 * 24 * 1000)) {
            const newEndTime = new Date(props.endTime);
            newEndTime.setFullYear(currentDate.getFullYear() + 1);
            difference = +newEndTime.getTime() - +currentDate;
        }

        const timeLeft: TimeLeft = {
            'days': Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))).toString() || '00',
            'hours': Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)).toString() || '00',
            'minutes': Math.max(0, Math.floor((difference / 1000 / 60) % 60)).toString() || '00',
            'seconds': Math.max(0, Math.floor((difference / 1000) % 60)).toString() || '00',
            'diff': difference,
        };

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents: JSX.Element[] = [];

    Object.keys(timeLeft).forEach(interval => {
        if (interval === 'diff') return;

        timerComponents.push(
            <div className="segment">
                <div className="digits">
                    {(("00" + timeLeft[interval]).substr(-2) || '00').split('').map(n => {
                        return <div className="digit">{n}</div>
                    })}
                </div>
                <div className="label">{interval}</div>
            </div>
        );

        if (interval !== 'seconds') {
            timerComponents.push(<div className="separator">:</div>);
        }
    });

    return (
        <div className="clock">
            {timeLeft.diff > 0 || timeLeft.diff < -(60 * 60 * 24 * 1000) ? timerComponents : <span className="message">{props.afterMessage}</span>}
        </div>
    );
};

export default Clock;
